@import "styles/variables.scss";
.Login {
  text-align: left;
  :global {
    .form-group{
      margin-bottom: 16px;
    }
    a{
      font-family: $primary-font;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $blue;
      text-decoration: none;
      &:hover{
        color: $hover-blue;
      }
    }
    .btn{
      .btn-loader{
        svg{
          max-height: 20px;
        }
      }
    }
  }
}
