@import "styles/variables.scss";

.DashboardTrends {
  background-color: $white;
  padding: 16px;
  min-height: 220px;
  :global {
    .trends__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      h2 {
        margin-bottom: 0;
        margin-right: 8px;
      }
    }
    .trends__sort {
      display: flex;
      gap: 12px;
    }
    .trends__data{
      background-color: #F9F9F9;
      display: flex;
      justify-content: space-between;
      padding: 16px;
      margin-bottom: 20px;
      h3{
        font-weight: 400;
        line-height: 20px;
        color: $text-grey2;
      }
      p{
        margin-bottom: 4px;
        font-weight: 700;
        font-size: 24px;
        line-height: 25px;
      }
    }
    .trends__data-col{
      flex-basis: 50%;
      &--green{
        p{
          color: $green2;
        }
      }
      &--blue{
        p{
          color: $blue2;
        }
      }
    }
    .trends__chart{
      margin-bottom: 24px;
    }
    .recharts-line-dots{
      circle{
        display: none;
      }
    }
    .recharts-xAxis{
      transform: translateY(4px);
    }
    .recharts-legend-wrapper{
      display: none;
    }
    .recharts-yAxis{
      &.yAxis{
        transform: translateX(8px);
      }
    } 
    .recharts-cartesian-axis-tick{
      tspan{
        fill: $text-grey2;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
