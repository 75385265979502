@import "styles/variables.scss";

.PreNavbar {
  position: relative;
  background-color: $text-black;
  z-index: 5;
  :global {
    .navbar__wrap {
      display: none;
      @media screen and (min-width: 991px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 8px 12px;
      }
    }
    .navbar--minimal{
      padding: 4px 0;
    }
    .left-col {
      margin-left: 4px;
      .btn {
        margin-left: 28px;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .center-col {
      width: 100%;
      max-width: 400px;
    }
    .nvam-logo-white{
      svg{
        height: 32px;
      }
    }
    .navbar-mob{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 4px;
      width: 100%;
      @media screen and (min-width: 991px) {
        display: none;
      }
      .navbar-mob__left{
        display: flex;
        gap: 16px;
        align-items: center;
        .burger-menu{
          padding: 6px 3px;
          cursor: pointer;
          &.active{
            span{
              &:nth-child(1){
                transform: rotate(45deg) translate(3px, -4px);
              }
              &:nth-child(2){
                opacity: 0;
              }
              &:nth-child(3){
                transform: rotate(-45deg) translate(2px, 4px);
              }
            }
          }
          span{
            display: block;
            width: 18px;
            height: 2px;
            margin-bottom: 3px;
            background-color: $white;
            transition: all 0.3s ease-in-out;
            &:last-child{
              margin-bottom: 0;
              transform-origin: 0% 100%;
            }
            &:first-child{
              transform-origin: 0% 0%;
            }

          }
        }
      }
      .navbar-mob__right{
        display: flex;
        align-items: center;
        gap: 20px;
        .search-icon{
          padding: 3px;
        }
        .btn{
          padding: 8px 12px;
        }
      }
    }
    .navbar-mob__search{
      background-color: $text-black;
      padding: 14px 16px;
      width: 100%;
      position: absolute;
      top: -100%;
      left: 0;
      z-index: 1;
      transition: all 0.3s ease-in-out;
      .search-bar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        width: 100%;
        .search-input{
          width: 100%;
          position: relative;
        }
        .close-btn{
          width: 13px;
          position: absolute;
          top: 11px;
          right: 17px;
          cursor: pointer;
        }
        span{
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: $white;
        }
      }
      &.search-show{
        top: 0;
      }
      &.search-hide{
        display: none;
      }
    }
  }
}
