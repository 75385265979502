@import "styles/variables.scss";

.Dashboard {
  padding: 12px 6px;
  display: flex;
  background-color: $bg-yellow;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  @media screen and (min-width: 1299px) {
    flex-wrap: nowrap;
  }
  :global {
    .dboard__col{
      border: 1px solid $light-grey;
      border-radius: 3px;
      flex-basis: 100%;
      width: 100%;
      margin: 0 6px;
      margin-bottom: 12px;
      &:last-child{
        margin-bottom: 0;
      }
      @media screen and (min-width: 1299px){
        margin-bottom: 0;
      }
      &--left{
        @media screen and (min-width: 1299px) {
          min-width: 460px;
          flex-basis: 37%;
        }
      }
      &--mid{
        border: none;
        @media screen and (min-width: 1299px) {
          max-height: calc(100vh - 147px);
          overflow-y: auto;
          min-width: 495px;
          flex-basis: 34%;
          // &::-webkit-scrollbar-thumb{
          //   transition: all 0.5s ease-in-out;
          //   background-color: transparent;
          // }
          // &::-webkit-scrollbar-track{
          //   transition: all 0.5s ease-in-out;
          //   background-color: transparent;
          // }
        }
        // &:hover{
        //   @media screen and (min-width: 1299px){
        //     &::-webkit-scrollbar-thumb{
        //       background-color: #B5B8B9;
        //     }
        //     &::-webkit-scrollbar-track{
        //       background-color: #E8E8E8;
        //     }
        //   }
        // }
      }
      &--right{
        @media screen and (min-width: 1299px) {
          flex-basis: 28%;
        }
      }
    }
    .dboard__row{
      border: 1px solid $light-grey;
      margin-bottom: 12px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    *{
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none; 
      &::-webkit-scrollbar{
        display: none;
      }
    }
  }
}