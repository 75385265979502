@import "styles/variables.scss";

.BrandNav {
  background-color: $black;
  text-align: center;
  max-height: 100px;
  :global {
    .nvam-logo-white{
      svg{
        height: 50px;
      }
    }
  }
}
