@import "styles/variables.scss";
.AuthLayout {
  width: 100%;
  background-color: $bg-yellow;
  :global{
    .container{
      max-width: 452px;
      display: flex;
      height: 100vh;
      align-items: center;
      justify-content: center;
      padding: 0 24px;
      @media #{$lg}{
        display: block;
      }
      .content-wrapper{
        width: 100%;
        text-align: center;
        @media #{$lg}{
          padding-top: 200px;
        }
        h2{
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
        }
      }
      .nvam-logo{
        margin-bottom: 24px;
      }
    }
  }
}

