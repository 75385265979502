@import "styles/variables.scss";

.SearchInput {
  :global {
    position: relative;
    .search-icon {
      position: absolute;
      top: 11px;
      left: 11px;
    }
    input{
      border: 1px solid $light-grey;
      border-radius: 18px;
      padding: 8px 28px 8px 32px;
      font-size: 16px;
      line-height: 20px;
      max-height: 36px;
      color: rgba(0, 0, 0, 0.37);
      &:focus, &:active{
        outline: none;
        box-shadow: none;
        & + .close-btn{
          opacity: 1;
          cursor: pointer;
        }
      }
    }
    .close-btn{
      width: 14px;
      height: 14px;
      position: absolute;
      right: 11px;
      top: 11px;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      path{
        fill-opacity: 0.7;
      }
    }
    .form-control{
      &:focus{
        box-shadow: none;
        border-color: $light-grey;
      }
    }
  }
}
