@import "styles/variables.scss";
.Search {
  :global {
    .results {
      background-color: $white;
      overflow-y: auto;
      width: 100%;
      max-width: 398px;
      max-height: 200px;
      border-radius: 10px;
      position: relative;
      z-index: 2;
      padding: 34px 16px 24px;
      border: 1px solid $light-grey;
    }
    .results__list{
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;
      ::-webkit-scrollbar-track{
        border-radius: 18px;
      }
      li{
        margin-bottom: 24px;
        &:last-child{
          margin-bottom: 0;
        }
        a{
          text-decoration: none;
          color: rgba(55, 60, 67, 0.38);
          span{
            color: rgba(55, 60, 67, 0.38);
            font-size: 16px;
            line-height: 20px;
            b{
              color: $text-black;
            }
          }
        }
      }
      .client-name{
        margin-bottom: 4px;
        span{
          color: rgba(55, 60, 67, 0.6);
          font-weight: 600;
          b{
            font-weight: 600;
          }
        }
      }
      .address{
        margin-bottom: 4px;
      }
    }
    .search-bar{
      position: relative;
      z-index: 5;
    }
  }
}
