@import "styles/variables.scss";

.DashboardJobs {
  background-color: $white;
  padding: 16px;
  min-height: 220px;
  :global {
    .job-status__content{
      display: flex;
      gap: 17px;
      padding: 16px 4px;
      justify-content: space-around;
    }
    .job-status__progress{
      text-align: center;
      h3{
        margin-bottom: 0;
        line-height: 20px;
        font-weight: 600;
      }
      p{
        line-height: 20px;
        margin-bottom: 0;
      }
    }
    .progress-bar {
      height: 72px;
      width: 72px;
      border-radius: 0px;
      background-color: #fff;
      margin-bottom: 10px;
    }
    .CircularProgressbar-text{
      font-weight: 600;
      line-height: 25px;
    }
  }
}
