@import "styles/variables.scss";

.TextInput {
  position: relative;
  :global {
    input {
      box-sizing: border-box;
      font-family: $primary-font;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      border: 1px solid $light-grey;
      border-radius: 3px;
      color: $text-black;
      &:-webkit-autofill {
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
      }
      &:active, &:focus{
        box-shadow: none;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        appearance: none;
        margin: 0;
        pointer-events: none;
        
      }
    }
    input[type=number] {
      -moz-appearance: textfield;   //For firefox
    }
    label.placeholder {
      display: none;
    }
    .form-errors {
      p {
        font-size: 14px;
        line-height: 20px;
        color: $red;
        margin-bottom: 0;
        margin-top: 4px;
      }
    }
    .form-control{
      &:focus{
        box-shadow: none;
        border-color: $light-grey;
      }
    }
  }
}

.TextInput__default {
  position: relative;
  :global {
    input {
      box-sizing: border-box;
      font-family: $primary-font;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      padding: 20px 16px 7px;
      border: 1px solid $light-grey;
      border-radius: 3px;
      color: $text-black;
      &:-webkit-autofill {
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
      }
    }
    .input-wrap {
      position: relative;
    }
    label.placeholder {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 16px;
      right: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
      color: $text-grey;
      opacity: 1;
    }
    input:focus + .placeholder,
    input:-webkit-autofill + .placeholder,
    :not(input[value=""]) + .placeholder {
      font-size: 12px;
      line-height: 15px;
      transform: translate(0, -9px);
    }
    .form-errors {
      p {
        font-size: 14px;
        line-height: 20px;
        color: $red;
        margin-bottom: 0;
        margin-top: 4px;
      }
    }
  }
}
.TextInput__prefix {
  :global {
    input {
      box-sizing: border-box;
      font-family: $primary-font;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: $text-black;
      padding: 20px 16px 7px 24px;
      border: 1px solid $light-grey;
      border-radius: 3px;
      &:-webkit-autofill {
        font-family: $primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
      }
    }
    label.placeholder {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 16px;
      right: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
      color: $text-grey;
      opacity: 1;
      &::after{
        content: '$';
        position: absolute;
        font-size: 16px;
        line-height: 21px;
        color: $text-black;
        transition: none;
        opacity: 0;
      }
    }
    input:focus, 
    input:-webkit-autofill,
    :not(input[value=""]){
      & + .placeholder{
        font-size: 12px;
        line-height: 15px;
        transform: translate(0, -9px);
        &::after{
          content: '$';
          left: 0px;
          top: 30px;
          opacity: 1;
        }
      }
    }
    .form-errors {
      p {
        font-size: 14px;
        line-height: 20px;
        color: $red;
        margin-bottom: 0;
        margin-top: 4px;
      }
    }
  }
}
.TextInput__standard {
  position: relative;
  :global {
    input{
      padding: 14px 16px; 
    }
  }
}
