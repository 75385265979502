@import "styles/variables.scss";

.NavNotifications {
  position: relative;
  display: inline-block;
  :global {
    .notification__bell {
      position: relative;
      cursor: pointer;
      svg {
        width: 16px;
        height: 20px;
      }
      .count {
        position: absolute;
        right: -12px;
        top: -5px;
        width: 20px;
        height: 20px;
        background-color: $light-red;
        border-radius: 50px;
        font-size: 11px;
        text-align: center;
        color: $white;
        line-height: 20px;
      }
    }
    .infinite-scroll-component {
      max-height: 536px;
      max-width: 100%;
    }
    .notification__popup {
      background-color: #fff;
      z-index: 9;
      min-width: 400px;
      // max-width: 400px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      border: 1px solid $light-grey;
    }
  }
}
