@import "styles/variables.scss";
.AdminNav {
  :global {
    .nav {
      background-color: #fef7d9;
      border-bottom: 1px solid #d0d3d4;
      display: none;
      @media screen and (min-width: 991px) {
        display: block;
      }
      .nav__list {
        list-style: none;
        display: flex;
        margin-bottom: 0;
        padding-left: 0;
        margin: 0 24px;
        .nav__item {
          padding: 0 24px;
          &:last-child {
            padding: 0;
            .nav__link {
              &::after {
                content: "";
                height: 0;
              }
            }
          }
          .nav__link {
            text-decoration: none;
            font-family: $primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 50px;
            color: $text-black;
            position: relative;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            &.active {
              font-weight: 700;
            }
            &::after {
              position: absolute;
              bottom: -15px;
              left: -24px;
              right: 0;
              width: 0;
              margin: auto;
              content: "";
              height: 2px;
              border-radius: 10px;
              background-color: $blue;
              transition: all 0.3s ease-in-out;
            }
            &:hover {
              color: $text-grey;
              &::after {
                width: calc(100% + 48px);
              }
            }
          }
        }
      }
      .nav__user {
        cursor: pointer;
        &:hover {
          .nav__link {
            color: $text-grey;
          }
        }
        a {
          margin-right: 4px;
        }
      }
      .nav__user-tray {
        min-width: 240px;
        max-width: 240px;
        background: #FFFFFF;
        box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        position: relative;
        z-index: 10;
        .user-tray__content {
          padding: 16px 16px 14px;
          h3{
            cursor: context-menu;
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 2px;
            max-width: 216px;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }
          p{
            cursor: context-menu;
            line-height: 24px;
            margin-bottom: 0;
            max-width: 216px;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }
        }
        .user-tray__cta {
          padding: 10px 16px 16px;
          width: 100%;
          display: inline-block;
          text-decoration: none;
          font-size: 16px;
          line-height: 24px;
          color: $text-black;
          border-top: 1px solid #dadbdd;
          cursor: pointer;
          &:hover {
            font-weight: 600;
          }
        }
        span {
          width: 19px;
          height: 19px;
          background-color: $white;
          transform: rotate(-45deg);
          border-radius: 0px 2px;
          display: inline-block;
          position: absolute;
          right: 8px;
          top: -9px;
        }
      }
    }
    .nav-mob {
      background-color: $white;
      height: calc(100vh - 64px);
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: 10;
      transition: all 0.3s ease-in-out;
      &.show {
        left: 0;
      }
      &.hide {
        left: -100%;
      }
      .nav-mob__list {
        list-style: none;
        padding: 32px 24px 24px;
        margin-bottom: 0;
        li {
          margin-bottom: 32px;
          .nav-mob__link {
            width: fit-content;
            text-decoration: none;
            color: $text-black;
            font-size: 16px;
            line-height: 20px;
            display: block;
            transition: all 0.3s ease-in-out;
            &.active {
              font-weight: 700;
            }
            &:hover {
              padding-left: 4px;
            }
          }
        }
      }
    }
  }
}
