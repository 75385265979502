@import "styles/variables.scss";

.DashboardCalendar {
  border-radius: 3px;
  background-color: $white;
  @media screen and (min-width: 991px){
    height: calc(100vh - 149px);
  }
  :global {
    .dboard-cal__header{
      border-bottom: 1px solid $light-grey;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      h2{
        margin-bottom: 0;
      }
    }
    .dboard-cal__content{
      padding: 12px 16px;
      max-height: calc(100vh - 334px);
      height: calc(100vh - 334px);
      overflow-y: auto;
    }
    .date-group{
      &.no-events{
        margin-bottom: 26px;
        h3{
          color: rgba(0, 0, 0, 0.38);
        }
      }
      .date-group__header{
        padding: 12px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
      h3{
        font-weight: 600;
        line-height: 20px;
      }
      .date-group__details{
        padding: 12px 16px 6px;
      }
    }
    .event-group{
      border-radius: 4px;
      background: #EEEEEE;
      padding: 6px 8px;
      margin-bottom: 8px;
      cursor: pointer;
      .event-group__row{
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
        gap: 20px;
        h3{
          margin-bottom: 0;
        }
        &--light{
          color: rgba(0, 0, 0, 0.38);
          margin-bottom: 0;
          p{
            color: rgba(0, 0, 0, 0.38);
            margin-bottom: 0;
            line-height: 20px;
          }
        }
        &--dark{
          h3{
            line-height: 20px;
            font-weight: 600;
            text-transform: capitalize;
          }
          .event-group__details{
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
        .event-group__details{
          align-self: flex-end;
          flex-basis: calc(100% - 69px);
          max-width: calc(100% - 69px);
          svg{
            max-width: 16px;  
            max-height: 16px;
          }
        }
        .event-group__time{
          text-align: right;
          align-self: flex-end;
          flex-basis: 65px;
          max-width: 65px;
        }
        .event-group__thumb{
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
