@import "styles/variables.scss";

.WeekDatePicker {
  max-width: 100%;
  padding: 10px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  :global {
    .aside-cal__header{
      display: flex;
      justify-content: space-between;
      margin-bottom: 17px;
    }
    .aside-cal__days{
      text-align: center;
      margin-bottom: 2px;
      .day{
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .aside-cal__dates{
      .date{
        text-align: center;
        cursor: pointer;
        span{
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          font-size: 18px;
          line-height: 24px;
        }
        &--current{
          span{
            background: #289FC1;
            border-radius: 50%;
            color: $white;
            font-weight: 700;
          }
        }
        &--selected{
          span{
            background: #d2e3fc;
            border-radius: 50%;
            color: #185abc;
            font-weight: 700;
          }
        }
      }
    }
    .aside-cal__month{
      display: flex;
      align-items: center;
      h2{
        font-weight: 600;
        margin-bottom: 0;
      }
      .chevron-down{
        width: 14px;
        margin-left: 4px;
      }
    }
    .aside-cal__nav{
      display: flex;
      align-items: center;
      .nav-arrow{
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        text-align: center;
        svg{
          width: 8px;
          height: 12px;
          path{
            fill: rgba(0, 0, 0, 0.6);
          }
        }
      }
      .nav-btn{
        color: #289FC1;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-left: 8px;
        cursor: pointer;
      }
    }
    .date-picker{
      margin-left: 0px;
      cursor: pointer;
    }
    .react-datepicker{
      .react-datepicker__day{
        border-radius: 0.3rem;
        &--selected{
          color: #185abc;
          background-color: #d2e3fc;
        }
        &--today{
          background-color: $blue;
          color: $white;
        }
      }
    }
  }
}
